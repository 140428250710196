import React from 'react'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockRaw from './block-raw'
import Container from './container'

import styles from './component-logos-bar.module.css'

function LogosBar (props) {
    return (
        <Container>
            <div className={styles.wrap}>
                <div className={styles.content}>
                    <h3 className={`${styles.headline} ${styles.bullet}`}>{props.title}</h3>
                    <BlockRaw text={props.body} />
                    <div className={styles.logos}>
                        {props.logos.map(logo => {
                            return (
                                <div key={logo._id} className={styles.logo}>
                                    <img src={imageUrlFor(buildImageObj(logo.logoColor)).url()} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default LogosBar