import React from 'react'

import TwoColumnsText from './component-two-columns-text'
import TwoColumnsLeftImage from './component-two-columns-left-image'
import TwoColumnsRightImage from './component-two-columns-right-image'
import ThreeColumnsText from './component-three-columns-text'
import ThreeColumnsWithImages from './component-three-columns-with-images'
import LogosBar from './component-logos-bar'
import OneColumnWithImage from './component-one-column-with-image'
import MailchimpForm from './component-mailchimp-form'

function Component (props) {
  return (
    <>
        {props._type === 'componentTwoColumnsText' && (
            <TwoColumnsText {...props} />
        )}

        {props._type === 'componentTwoColumnsLeftImage' && (
            <TwoColumnsLeftImage {...props} />
        )}

        {props._type === 'componentTwoColumnsRightImage' && (
            <TwoColumnsRightImage {...props} />
        )}

        {props._type === 'componentThreeColumnsText' && (
            <ThreeColumnsText {...props} />
        )}

        {props._type === 'componentThreeColumnWithImages' && (
            <ThreeColumnsWithImages {...props} />
        )}

        {props._type === 'componentLogosBar' && (
            <LogosBar {...props} />
        )}

        {props._type === 'componentMailchimpForm' && (
            <MailchimpForm {...props} />
        )}

        {props._type === 'componentOneColumnWithImage' && (
            <OneColumnWithImage {...props} />
        )}
    </>
  )
}

export default Component