import React from 'react'
import BlockRaw from './block-raw'
import Container from './container'

import styles from './component-two-columns-text.module.css'

function TwoColumnsText (props) {
    return (
        <Container>
            <div className={styles.columns}>
                <div className={styles.content}>
                    <h3 className={`${styles.headline} ${styles.bullet}`}>{props.columnOneTitle}</h3>
                    <BlockRaw text={props.columnOneBody} />
                    {props.columnOneButtonLink && (
                        <a href={props.columnOneButtonLink} className={`${styles.button} ${styles.primary}`}>{props.columnOneButtonLabel}</a>
                    )}
                </div>
                <div className={styles.content}>
                    <h3 className={`${styles.headline} ${styles.bullet}`}>{props.columnTwoTitle}</h3>
                    <BlockRaw text={props.columnTwoBody} />
                    {props.columnTwoButtonLink && (
                        <a href={props.columnTwoButtonLink} className={`${styles.button} ${styles.primary}`}>{props.columnTwoButtonLabel}</a>
                    )}
                </div>
            </div>
        </Container>
    )
}

export default TwoColumnsText