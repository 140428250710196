import React from 'react'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockRaw from './block-raw'
import Container from './container'

import styles from './component-two-columns-right-image.module.css'

function TwoColumnsRightImage (props) {
    return (
        <Container>
            <div className={styles.columns}>
                <div className={styles.content}>
                    <h3 className={`${styles.headline} ${styles.bullet}`}>{props.title}</h3>
                    <BlockRaw text={props.body} />
                    {props.buttonLink && (
                        <a href={props.buttonLink} className={`${styles.button} ${styles.primary}`}>{props.buttonLabel}</a>
                    )}
                </div>
                <div className={styles.image}>
                    <img src={imageUrlFor(buildImageObj(props.image)).url()} />
                </div>
            </div>
        </Container>
    )
}

export default TwoColumnsRightImage