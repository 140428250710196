import React from 'react'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockRaw from './block-raw'
import Container from './container'

import styles from './component-three-columns-with-images.module.css'

function ThreeColumnsWithImages (props) {
    return (
        <Container>
            <div className={styles.columns}>
                <div className={styles.content}>
                    <div className={styles.image}>
                        <img src={imageUrlFor(buildImageObj(props.columnOneImage)).url()} />
                    </div>
                    <h5 className={`${styles.headline} ${styles.bullet}`}>{props.columnOneTitle}</h5>
                    <BlockRaw text={props.columnOneBody} />
                    {props.columnOneButtonLink && (
                        <a href={props.columnOneButtonLink} className={`${styles.button} ${styles.primary}`}>{props.columnOneButtonLabel}</a>
                    )}
                </div>
                <div className={styles.content}>
                    <div className={styles.image}>
                        <img src={imageUrlFor(buildImageObj(props.columnTwoImage)).url()} />
                    </div>
                    <h5 className={`${styles.headline} ${styles.bullet}`}>{props.columnTwoTitle}</h5>
                    <BlockRaw text={props.columnTwoBody} />
                    {props.columnTwoButtonLink && (
                        <a href={props.columnTwoButtonLink} className={`${styles.button} ${styles.primary}`}>{props.columnTwoButtonLabel}</a>
                    )}
                </div>
                <div className={styles.content}>
                    <div className={styles.image}>
                        <img src={imageUrlFor(buildImageObj(props.columnThreeImage)).url()} />
                    </div>
                    <h5 className={`${styles.headline} ${styles.bullet}`}>{props.columnThreeTitle}</h5>
                    <BlockRaw text={props.columnThreeBody} />
                    {props.columnThreeButtonLink && (
                        <a href={props.columnThreeButtonLink} className={`${styles.button} ${styles.primary}`}>{props.columnThreeButtonLabel}</a>
                    )}
                </div>
            </div>
        </Container>
    )
}

export default ThreeColumnsWithImages