import React from 'react'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockRaw from './block-raw'
import Container from './container'

import styles from './component-mailchimp-form.module.css'

function MailchimpForm (props) {
    return (
        <Container>
            <div className={styles.wrap}>
                {props.image && (
                    <div className={styles.image}>
                        <img src={imageUrlFor(buildImageObj(props.image)).url()} />
                    </div>
                )}
                <div className={styles.content}>
                    <h3 className={`${styles.headline} ${styles.bullet}`}>{props.title}</h3>
                    <BlockRaw text={props.body} />
                    <div id="mc_embed_signup">
                        <form action={`https://redpoint.us19.list-manage.com/subscribe/post?u=5a2a3aaa4b27797f58d771912&amp;id=${props.formId}`} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className={styles.validate} target="_blank" noValidate>
                            <div id="mc_embed_signup_scroll">
                                <input type="email" name="EMAIL" className={styles.email} id="mce-EMAIL" placeholder="email address" required />
                            
                                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name={`b_5a2a3aaa4b27797f58d771912_${props.formId}`} tabIndex="-1" defaultValue="" /></div>
                                <input type="submit" value={props.buttonLabel || "Subscribe"} name="subscribe" id="mc-embedded-subscribe" className={`${styles.button} ${styles.primary}`} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default MailchimpForm