import React from 'react'

const replaceBreaksWithParagraphs = (input) => {
    const paragraphs = filterEmpty(input.split('\n')).map((paragraph, index) => {
        return <p key={index}>{paragraph}</p>
    });
    return paragraphs
}

const filterEmpty = (arr) => {
    var new_arr = [];
    
    for (var i = arr.length-1; i >= 0; i--)
    {
        if (arr[i] != "")
            new_arr.push(arr.pop());
        else
            arr.pop();
    }
    
    return new_arr.reverse();
}

const BlockRaw = ({ text }) => replaceBreaksWithParagraphs(text);

export default BlockRaw