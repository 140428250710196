import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hero'
import Component from '../components/lp-component'

export const query = graphql`
  query LandingTemplateQuery($id: String!) {
    landing: sanityLandingPage(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      component {
        ... on SanityComponentMailchimpForm {
          _key
          _type
          image {
            asset {
              _id
              url
            }
          }
          title
          buttonLabel
          formId
          body
        }
        ... on SanityComponentLogosBar {
          _key
          _type
          title
          body
          logos {
            _id
            logoColor {
              asset {
                _id
                url
              }
            }
          }
        }
        ... on SanityComponentOneColumnWithImage {
          _key
          _type
          title
          buttonLabel
          buttonLink
          image {
            asset {
              _id
              url
            }
          }
          body
        }
        ... on SanityComponentThreeColumnWithImages {
          _key
          _type
          columnOneButtonLabel
          columnOneButtonLink
          columnOneTitle
          columnOneBody
          columnOneImage {
            asset {
              _id
              url
            }
          }
          columnThreeButtonLabel
          columnThreeButtonLink
          columnThreeTitle
          columnThreeBody
          columnThreeImage {
            asset {
              url
              _id
            }
          }
          columnTwoButtonLabel
          columnTwoButtonLink
          columnTwoTitle
          columnTwoBody
          columnTwoImage {
            asset {
              url
              _id
            }
          }
        }
        ... on SanityComponentThreeColumnsText {
          _key
          _type
          columnOneButtonLabel
          columnOneButtonLink
          columnOneTitle
          columnOneBody
          columnThreeButtonLabel
          columnThreeButtonLink
          columnThreeTitle
          columnThreeBody
          columnTwoButtonLabel
          columnTwoButtonLink
          columnTwoTitle
          columnTwoBody
        }
        ... on SanityComponentTwoColumnsLeftImage {
          _key
          _type
          title
          buttonLink
          buttonLabel
          image {
            asset {
              _id
              url
            }
          }
          body
        }
        ... on SanityComponentTwoColumnsRightImage {
          _key
          _type
          buttonLabel
          buttonLink
          title
          image {
            asset {
              _id
              url
            }
          }
          body
        }
        ... on SanityComponentTwoColumnsText {
          _key
          _type
          columnOneButtonLabel
          columnOneButtonLink
          columnOneTitle
          columnOneBody
          columnTwoButtonLabel
          columnTwoButtonLink
          columnTwoTitle
          columnTwoBody
        }
      }
      metaTitle
      metaDescription
      metaKeywords
    }
  }
`

const LandingTemplate = props => {
  const { data, errors } = props
  const landing = data && data.landing
  
  return (
    <Layout palette="invert">
      {/* {errors && <SEO title='GraphQL Error' />}
      {landing && <SEO title={landing.title || 'Untitled'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )} */}

      <SEO title={landing.metaTitle || landing.title} description={landing.metaDescription} keywords={landing.metaKeywords} />

      <Hero palette="invert" >
        {landing.title}
      </Hero>

      {landing.component && landing.component.length > 0 && 
        landing.component.map(component => {
          return (
            <Component key={component._key} {...component} />
          )
        })
      }
    </Layout>
  )
}

export default LandingTemplate
